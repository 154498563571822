//-----------------------------------------------------------------------------
// Imports
//-----------------------------------------------------------------------------
import * as actions from '@/api/actions'
import * as mutations from '@/api/mutations'
import * as queries from '@/api/queries'

//-----------------------------------------------------------------------------
// Export
//-----------------------------------------------------------------------------
export const action = actions
export const mutation = mutations
export const query = queries